// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-suggested-js": () => import("./../../../src/pages/suggested.js" /* webpackChunkName: "component---src-pages-suggested-js" */),
  "component---src-posts-genshin-routing-genshin-routing-mdx": () => import("./../../../src/posts/genshin-routing/genshin-routing.mdx" /* webpackChunkName: "component---src-posts-genshin-routing-genshin-routing-mdx" */),
  "component---src-posts-genshin-wishes-genshin-wishes-p-1-mdx": () => import("./../../../src/posts/genshin-wishes/genshin-wishes-p1.mdx" /* webpackChunkName: "component---src-posts-genshin-wishes-genshin-wishes-p-1-mdx" */),
  "component---src-posts-genshin-wishes-genshin-wishes-p-2-mdx": () => import("./../../../src/posts/genshin-wishes/genshin-wishes-p2.mdx" /* webpackChunkName: "component---src-posts-genshin-wishes-genshin-wishes-p-2-mdx" */),
  "component---src-posts-stardew-farming-stardew-farming-mdx": () => import("./../../../src/posts/stardew-farming/stardew-farming.mdx" /* webpackChunkName: "component---src-posts-stardew-farming-stardew-farming-mdx" */),
  "component---src-posts-welcome-welcome-to-my-site-mdx": () => import("./../../../src/posts/welcome/welcome-to-my-site.mdx" /* webpackChunkName: "component---src-posts-welcome-welcome-to-my-site-mdx" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

